<template>
<div>
  <b-container>
    <b-row class="my-5">
      <b-col>
        <h1 style="font-size: 24px; font-weight: bold;">WORK IN PROGRESS - Welcome to the ESG Metrics Dashboard - WORK IN PROGRESS</h1>
        <p>Curated by a global network of specialists, KPMG's ESG Metrics Dashboard enables you to make informed decisions, by simplifying the
          complex landscape of ESG reporting metrics across major frameworks.
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mb-5" lg="4" md="12">
        <div class="shadow p-0 m-0 bg-white" style="height: 220px;">
          <div class="p-0 m-0" style="text-align: center; height: 130px;"><img src="/img/icon1.png" style="width: 200px" /></div>
          <div class="p-0 m-0" style="text-align: center;"><router-link :to="{ name: 'Metrics' }" class="link-card">{{ metrics.length }}</router-link></div>
          <div class="p-0 m-0" style="text-align: center;"><router-link :to="{ name: 'Metrics' }" class="link-card">Metrics</router-link></div>
        </div>
      </b-col>
      <b-col class="mb-5" lg="4" md="12">
        <div class="shadow p-0 m-0 bg-white" style="height: 220px;">
          <div class="p-0 m-0" style="text-align: center; height: 130px;"><img src="/img/icon2.png" style="width: 200px" /></div>
          <div class="p-0 m-0" style="text-align: center;"><router-link :to="{ name: 'DataElements' }" class="link-card">{{ dataelements.length }}</router-link></div>
          <div class="p-0 m-0" style="text-align: center;"><router-link :to="{ name: 'DataElements' }" class="link-card">Data Elements</router-link></div>
        </div>
      </b-col>
      <b-col class="mb-5" lg="4" md="12">
        <div class="shadow p-0 m-0 bg-white" style="height: 220px;">
          <div class="p-0 m-0" style="text-align: center; height: 130px;"><img src="/img/icon3.png" style="width: 200px" /></div>
          <div class="p-0 m-0" style="text-align: center;"><router-link :to="{ name: 'MetricsReportingframeworks' }" class="link-card">{{ reportingframeworks.length }}</router-link></div>
          <div class="p-0 m-0" style="text-align: center;"><router-link :to="{ name: 'MetricsReportingframeworks' }" class="link-card">Reporting Frameworks</router-link></div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mb-5" lg="8" md="12">
        <h2 style="font-size: 20px; font-weight: bold;">About ESG Metrics Dashboard</h2>
        <p>An introduction from Myra, Rachel and Hilary</p>
        <b-container class="shadow p-3 bg-white">
          <b-row>
            <b-col class="text-center" lg="4" sm="12">
              <div>
                <div style="margin: 0 auto; clip-path: circle(50% at 50% 50%); background-image: url('/img/MyraDoyle.png'); background-position: center; background-size: cover; width: 153px; height: 153px;"></div>
              </div>
              <p class="mb-0"><strong>Myra Doyle</strong></p>
              <p class="mb-0"><small>Partner, Financial Services Finance Transformation</small></p>
              <p><small>KPMG UK</small></p>
              <div>
                <div style="margin: 0 auto; clip-path: circle(50% at 50% 50%); background-image: url('/img/RachelTracey.png'); background-position: center; background-size: cover; width: 153px; height: 153px;"></div>
              </div>
              <p class="mb-0"><strong>Rachel Tracey</strong></p>
              <p class="mb-0"><small>Partner, FS Technology Enablement</small></p>
              <p><small>KPMG UK</small></p>
              <div>
                <div style="margin: 0 auto; clip-path: circle(50% at 50% 50%); background-image: url('/img/HilaryEastman.png'); background-position: center; background-size: cover; width: 153px; height: 153px;"></div>
              </div>
              <p class="mb-0"><strong>Hilary Eastman</strong></p>
              <p class="mb-0"><small>Partner, ESG Reporting</small></p>
              <p><small>KPMG UK</small></p>
            </b-col>
            <b-col lg="8" sm="12">
              <div>
                <p>Multinational companies must overcome several distinct data complications when executing their ESG reporting journey and developing their ESG Strategy to transform.</p>
                <p><strong>Global influence</strong></p>
                <p>Entities with significant operations in multiple jurisdictions need to understand the key differences across ESG Reporting frameworks including the ESRS and ISSB standards and SEC proposals because they are likely to be subject to more than one set of requirements.</p>
                <p><strong>Data Governance</strong></p>
                <p>Having a standard approach and tooling for disclosure and metric comparisons will allow entities to gain full clarity on the scale of their ESG disclosures and most effective implementation path  (group v local) available based on their corporate structure.</p>
                <p><strong>Data Gaps</strong></p>
                <p>Many companies don't have the data to support quality disclosures, or data is not available from downstream/upstream suppliers. Especially when data may require varying levels of independent audit and attestation.</p>
                <p><strong>Data Strategy</strong></p>
                <p>Disclosure and Metric comparison across framework can be used to identify common data elements and develop a stream lined data model to report against multiple framework and establish an effective and future proof  ESG Reporting Data Strategy.</p>
                <p><strong>How can KPMG Support</strong></p>
                <p>KPMG experts employing our proprietary ESG Metric tool to help our clients</p>
                <ul>
                  <li>Maximise the interoperability of the regulations and data to maximise the efficiency to collect and report,</li>
                  <li>Accelerated delivery and end to end understanding of a regulation from disclosure to indicative data sources,</li>
                  <li>Trust in their disclosure and data to meet compliance across multiple frameworks / regulations,</li>
                  <li>Prioritised pathway to assurance-ready data maturity</li>
                </ul>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
      <b-col lg="4" md="12">
        <h2 style="font-size: 20px; font-weight: bold;">News</h2>
        <p>Recent industry updates</p>
        <div v-if="!loading">
          <div class="shadow py-3 px-3 mb-4 bg-white" v-for="(item, index) in news" :key="'news-' + index">
            <div>
              <router-link :to="{ name: 'NewsItem', params: { id: item.id }}">
                <div><strong>{{item.name}}</strong></div>
                <div><small>{{moment(item.date).format('YYYY-MM-DD')}} - {{ item.countryName }} - {{ item.publisher }}</small></div>
              </router-link>
            </div>
          </div>
          <div class="shadow py-3 px-3 mb-4 bg-white">
            <router-link :to="{ name: 'Topic', params: { id: 157 }}" class="link-card">More news</router-link>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'MetricsHome',
  components: {
  },
  computed: {
    dataelements: function () {
      let result = 8
      if (this.$store.state.dataelements > 8) {
        result = this.$store.state.dataelements
      }
      return result
    },
    metrics: function () {
      return this.$store.state.metrics
    },
    reportingframeworks: function () {
      return this.$store.state.reportingframeworks
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  data () {
    return {
      loading: true,
      news: [],
      moment: moment
    }
  },
  created: async function () {
    this.news = await this.$Amplify.API.get('cosmos', '/news/topic/157/standardformat/limit/7')
    this.loading = false
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
.link-card {
  font-size: 16px;
  font-weight: bold;
}
</style>
